<template>
  <a-modal
    dialogClass="translate-modal"
    :zIndex="1000"
    :maskClosable="false"
    :dialogStyle="isAddTerm ? { left: '200px', top: '130px' } : {}"
    :width="715"
    :visible="visible"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <div @mouseover="topCenterMouseover" slot="closeIcon">
      <a-icon type="close" />
    </div>
    <div @mouseover="topCenterMouseover" slot="title" class="modal-title">{{ isAddTerm ? '预览' : '编辑术语表' }}</div>
    <div @mouseover="topCenterMouseover" v-if="!isEdit" :style="backCss">
      <a-icon
        type="left-circle"
        @click="prev"
        :class="curRow && curRow.hasLast ? 'term-prev change-page' : 'term-prev'"
        :style="
          curRow && curRow.hasLast
            ? curRow.term.termType == '人物'
              ? 'top:250px;'
              : 'top:150px;'
            : 'color:rgba(0,0,0,0.25);'
        "
      />
      <a-icon
        type="right-circle"
        @click="next"
        :class="curRow && curRow.hasNext ? 'term-next change-page' : 'term-next'"
        :style="
          curRow && curRow.hasNext
            ? curRow.term.termType == '人物'
              ? 'top:250px;'
              : 'top:150px;'
            : 'color:rgba(0,0,0,0.25);'
        "
      />
    </div>
    <div @mouseover="topCenterMouseover" class="term-form" v-if="isEdit">
      <a-form-model
        style="padding-bottom: 70px;"
        ref="dataForm"
        layout="horizontal"
        :model="formData"
        :rules="rules"
        v-bind="layout"
      >
        <a-form-model-item label="分类" prop="termTypeName">
          <a-select placeholder="请选择分类" @change="templateChange" v-model="formData.termTypeName">
            <a-select-option v-for="i in templateNameList" :value="i.name" :key="i.id">
              {{ i.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <template v-if="formType == '人物'">
          <a-form-model-item label="头像" class="upload-item" prop="subjectImgUrl">
            <div style="display:flex;flex-wrap:wrap;align-items:center;">
              <a-upload
                name="avatar"
                list-type="picture-card"
                class="avatar-uploader avatar-uploader-first"
                :show-upload-list="false"
                :before-upload="file => beforeCroUpload(file, 0)"
              >
                <a-icon v-if="formData.subjectImgUrl[0]" @click.stop="clearUrl(0)" type="close-circle" />
                <div v-if="formData.subjectImgUrl[0]" class="avatar-show">
                  <img :src="formData.subjectImgUrl[0]" style="width:90px;height:90px" />
                </div>
                <div v-else>
                  <a-icon :type="avatarLoading ? 'loading' : 'plus'" />
                  <div class="ant-upload-text">主头像上传</div>
                </div>
              </a-upload>
              <Editor
                ref="editor"
                style="height: 30px; overflow: hidden;width:108px;position:relative;top:-1px;"
                class="img-editor"
                v-model="copyUrl1"
                :defaultConfig="editorConfig"
                :mode="mode"
                @onCreated="onCreated"
                @customPaste="customPaste"
              />
            </div>
            <div style="display:flex;flex-wrap:wrap;align-items:center;">
              <a-upload
                name="avatar"
                list-type="picture-card"
                class="avatar-uploader"
                :show-upload-list="false"
                :before-upload="file => beforeCroUpload(file, 1)"
              >
                <a-icon v-if="formData.subjectImgUrl[1]" @click.stop="clearUrl(1)" type="close-circle" />
                <div v-if="formData.subjectImgUrl[1]" class="avatar-show">
                  <img :src="formData.subjectImgUrl[1]" style="width:90px;height:90px" />
                </div>
                <div v-else>
                  <a-icon :type="avatarLoading ? 'loading' : 'plus'" />
                  <div class="ant-upload-text">上传</div>
                </div>
              </a-upload>
              <Editor
                ref="editor"
                style="height: 30px; overflow: hidden;width:106px"
                class="img-editor"
                v-model="copyUrl2"
                :defaultConfig="editorConfig2"
                :mode="mode"
                @onCreated="onCreated2"
                @customPaste="customPaste2"
              />
            </div>
            <div style="display:flex;flex-wrap:wrap;align-items:center;">
              <a-upload
                name="avatar"
                list-type="picture-card"
                class="avatar-uploader"
                :show-upload-list="false"
                :before-upload="file => beforeCroUpload(file, 2)"
              >
                <a-icon v-if="formData.subjectImgUrl[2]" @click.stop="clearUrl(2)" type="close-circle" />
                <div v-if="formData.subjectImgUrl[2]" class="avatar-show">
                  <img :src="formData.subjectImgUrl[2]" style="width:90px;height:90px" />
                </div>
                <div v-else>
                  <a-icon :type="avatarLoading ? 'loading' : 'plus'" />
                  <div class="ant-upload-text">上传</div>
                </div>
              </a-upload>
              <Editor
                ref="editor"
                style="height: 30px; overflow: hidden;width:106px"
                class="img-editor"
                v-model="copyUrl3"
                :defaultConfig="editorConfig3"
                :mode="mode"
                @onCreated="onCreated3"
                @customPaste="customPaste3"
              />
            </div>
          </a-form-model-item>
          <!--           <a-form-model-item label="原文名" prop="originName">
            <a-input v-model="formData.originName" placeholder="请输入原文名"></a-input>
          </a-form-model-item> -->
          <div class="other-item origin-other-item">
            原文名
          </div>
          <a-form-model-item label="姓" prop="originAll">
            <a-input v-model="formData.originFamilyName" @change="originNameChange" placeholder="请输入姓"></a-input>
          </a-form-model-item>
          <a-form-model-item label="名" prop="originAll">
            <a-input v-model="formData.originGivenName" @change="originNameChange" placeholder="请输入名"></a-input>
            <div
              class="check-origin"
              v-if="formData.originName && terms.filter(w => formData.originName.indexOf(w.value) > -1).length"
            >
              <div v-for="word in terms.filter(w => formData.originName == w.value)" :key="word.id">
                <span>{{ word.value + '→' + word.target }}</span>
              </div>
            </div>
          </a-form-model-item>
          <!--           <a-form-model-item label="译文名（全名）" prop="processedName">
            <a-input v-model="formData.processedName" placeholder="请输入译文名"></a-input>
          </a-form-model-item>
          <a-form-model-item v-if="departName == '日语部'" label="注音" prop="processedName">
            <a-spin :spinning="inputLoading">
              <a-input v-model="formData.phoneticText" @input="phoneticTextInput" placeholder="请输入注音"></a-input>
            </a-spin>
          </a-form-model-item> -->
          <div class="other-item">
            <!-- <a-icon :class="'open-oher-item'" type="right" /> -->
            译文名
          </div>
          <a-form-model-item label="姓" prop="processedName" class="phonetic-item">
            <span v-if="(departName.indexOf('韩语部') > -1 && isDepart) || departName.indexOf('韩语部') == -1">
              <a-input v-model="formData.processedFamilyName" style="width:200px" placeholder="请输入姓"></a-input>
              <span
                style="margin-left:5px;"
                v-if="formData.phoneticFamilyNameTextList && formData.phoneticFamilyNameTextList.length"
              >
                {{ formData.phoneticFamilyNameTextList.map(p => p.value).join('') }}
              </span>
            </span>
            <a-input v-else v-model="formData.processedFamilyName" placeholder="请输入姓"></a-input>
            <a-button
              :loading="inputLoading"
              size="small"
              v-if="departName == '日语部'"
              style="margin-left:5px;"
              @click="addPhonetic(formData.processedFamilyName, 'processedFamilyName')"
              >获取注音</a-button
            >
            <a-button
              size="small"
              style="margin-left:5px;"
              @click="recommendText(formData.originName, 'processedFamilyName')"
              v-if="departName.indexOf('韩语部') > -1 && isDepart"
              >查看推荐用字</a-button
            >
          </a-form-model-item>
          <a-form-model-item label="名" prop="processedName" class="phonetic-item">
            <span v-if="(departName.indexOf('韩语部') > -1 && isDepart) || departName.indexOf('韩语部') == -1">
              <a-input v-model="formData.processedGivenName" style="width:200px" placeholder="请输入名"></a-input>
              <span
                style="margin-left:5px;"
                v-if="formData.phoneticGivenNameTextList && formData.phoneticGivenNameTextList.length"
              >
                {{ formData.phoneticGivenNameTextList.map(p => p.value).join('') }}
              </span>
            </span>
            <a-input v-else v-model="formData.processedGivenName" style="width:200px" placeholder="请输入名"></a-input>
            <a-button
              :loading="inputLoading"
              size="small"
              v-if="departName == '日语部'"
              style="margin-left:5px;"
              @click="addPhonetic(formData.processedGivenName, 'processedGivenName')"
              >获取注音</a-button
            >
            <a-button
              size="small"
              style="margin-left:5px;"
              @click="recommendText(formData.originName, 'processedGivenName')"
              v-if="departName.indexOf('韩语部') > -1 && isDepart"
              >查看推荐用字</a-button
            >
          </a-form-model-item>
          <a-form-model-item label="略称（小名）" prop="originNickname" class="phonetic-item">
            <span v-if="(departName.indexOf('韩语部') > -1 && isDepart) || departName.indexOf('韩语部') == -1">
              <a-input v-model="formData.originNickname" style="width:200px" placeholder="请输入略称"></a-input>
              <span
                style="margin-left:5px;"
                v-if="formData.phoneticNickNameTextList && formData.phoneticNickNameTextList.length"
              >
                {{ formData.phoneticNickNameTextList.map(p => p.value).join('') }}
              </span>
            </span>
            <a-input v-else v-model="formData.originNickname" style="width:200px" placeholder="请输入略称"></a-input>
            <a-button
              :loading="inputLoading"
              size="small"
              v-if="departName == '日语部'"
              style="margin-left:5px;"
              @click="addPhonetic(formData.originNickname, 'originNickname')"
              >获取注音</a-button
            >
            <a-button
              style="margin-left:5px;"
              size="small"
              @click="recommendText(formData.originName, 'originNickname')"
              v-if="departName.indexOf('韩语部') > -1 && isDepart"
              >查看推荐用字</a-button
            >
          </a-form-model-item>
          <!--           <a-form-model-item
            v-if="departName.indexOf('韩语部') > -1"
            label="译文名（全名）"
            prop="processedName"
            class="phonetic-item"
          >
            <span>
              <a-input v-model="formData.processedName" style="width:200px" placeholder="请输入译文名"></a-input>
              <span style="margin-left:5px;" v-if="formData.phoneticTextList && formData.phoneticTextList.length">
                {{ formData.phoneticTextList.map(p => p.value).join('') }}
              </span>
            </span>
            <a-button
              :loading="inputLoading"
              size="small"
              v-if="departName == '日语部'"
              @click="addPhonetic(formData.processedName, 'processedName')"
              >获取注音</a-button
            >
            <a-button
              size="small"
              @click="recommendText(formData.originName, 'processedName')"
              v-if="departName.indexOf('韩语部') > -1"
              >查看推荐用字</a-button
            >
          </a-form-model-item> -->
          <a-form-model-item
            v-if="departName == '日语部'"
            :disabled="!checkPermission('phoneticStatus:edit') && isEdit"
            label="注音情况"
            prop="processedName"
          >
            <a-radio-group v-model="formData.phoneticStatus">
              <a-radio style="height:30px" :value="0">未注音</a-radio>
              <a-radio style="height:30px" :value="1">已注音</a-radio>
              <a-radio style="height:30px" :value="2">姓未注音</a-radio>
              <a-radio style="height:30px" :value="3">名未注音</a-radio>
              <a-radio style="height:30px" :value="4">每次注音</a-radio>
              <a-radio style="height:30px" :value="5">不需要注音</a-radio>
            </a-radio-group>
          </a-form-model-item>
          <!--           <a-form-model-item v-if="departName == '日语部'" label="注音情况" prop="processedName">
            <a-radio-group v-model="formData.autoPhoneticTrigger" style="line-height:40px">
              <a-radio :value="0">作品中每次</a-radio>
              <a-radio :value="1">作品中首次</a-radio>
              <a-radio :value="2">每话首次</a-radio>
              <br />
              <a-radio :value="3">每页首次</a-radio>
              <a-radio :value="4">人员校正</a-radio>
            </a-radio-group>
          </a-form-model-item> -->
          <!--           <a-form-model-item v-if="departName.indexOf('韩语部') > -1" label="略称（小名）" prop="originNickname">
            <a-input v-model="formData.originNickname" placeholder="请输入略称"></a-input>
          </a-form-model-item> -->
          <a-form-model-item label="初次登场章节" prop="debut">
            <a-input-number :min="0" v-model="formData.debut" placeholder="请输入初次登场章节"></a-input-number>
          </a-form-model-item>
          <a-form-model-item label="性别" prop="gender">
            <a-select placeholder="请选择性别" v-model="formData.gender">
              <a-select-option :key="1" value="男">
                男
              </a-select-option>
              <a-select-option :key="2" value="女">
                女
              </a-select-option>
              <a-select-option :key="3" value="无性别">
                无性别
              </a-select-option>
              <a-select-option :key="4" value="待定">
                待定
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="身份（职业）" prop="identity">
            <a-input v-model="formData.identity" placeholder="请输入身份"></a-input>
          </a-form-model-item>
          <a-form-model-item label="自称">
            <a-input v-model="formData.call" placeholder="请输入自称"></a-input>
          </a-form-model-item>
          <a-form-model-item label="语气">
            <a-input v-model="formData.tone" placeholder="请输入语气"></a-input>
          </a-form-model-item>
          <a-form-model-item label="说明" prop="description">
            <a-input v-model="formData.description" placeholder="限制五十字以内"></a-input>
          </a-form-model-item>
          <template v-if="formData.termTypeName != '配角' && formData.termTypeName != '路人'">
            <!--             :prop="'characters.' + idx + '.name'"
                          :rules="{
                required: true,
                message: '请输入性格',
                trigger: 'blur'
              }" -->
            <a-form-model-item
              v-for="(i, idx) in formData.characters"
              :key="idx + 1 + 'xg'"
              :label="idx == 0 ? '性格' : ''"
              v-bind="
                idx === 0
                  ? layout
                  : {
                      wrapperCol: {
                        xs: { span: 24, offset: 0 },
                        sm: { span: 18, offset: 6 }
                      }
                    }
              "
            >
              <div>
                <a-input-group compact>
                  <a-input style="width: 32%" v-model="i.name" placeholder="请输入性格" />
                  <a-input style="width: 68%" v-model="i.description" placeholder="请输入性格描述" />
                </a-input-group>
                <div class="end-btns" style="top: -5px;">
                  <a-button
                    class="item-btn"
                    :disabled="formData.characters.length <= 1"
                    shape="circle"
                    size="small"
                    icon="minus"
                    @click="delxg(formData.characters, idx)"
                  />
                  <a-button
                    class="item-btn"
                    shape="circle"
                    size="small"
                    icon="plus"
                    @click="addxg(formData.characters, idx)"
                  />
                </div>
              </div>
            </a-form-model-item>
          </template>
          <template v-else>
            <a-form-model-item
              v-for="(i, idx) in formData.characters"
              :key="idx + 1 + 'xg'"
              :label="idx == 0 ? '性格' : ''"
              v-bind="
                idx === 0
                  ? layout
                  : {
                      wrapperCol: {
                        xs: { span: 24, offset: 0 },
                        sm: { span: 18, offset: 6 }
                      }
                    }
              "
            >
              <div>
                <a-input-group compact>
                  <a-input style="width: 32%" v-model="i.name" placeholder="请输入性格" />
                  <a-input style="width: 68%" v-model="i.description" placeholder="请输入性格描述" />
                </a-input-group>
                <div class="end-btns" style="top: -5px;">
                  <a-button
                    class="item-btn"
                    :disabled="formData.characters.length <= 1"
                    shape="circle"
                    size="small"
                    icon="minus"
                    @click="delxg(formData.characters, idx)"
                  />
                  <a-button
                    class="item-btn"
                    shape="circle"
                    size="small"
                    icon="plus"
                    @click="addxg(formData.characters, idx)"
                  />
                </div>
              </div>
            </a-form-model-item>
          </template>
          <a-form-model-item label="是否有团体" prop="tt">
            <a-radio-group v-model="formData.hasTeam" @change="teamRadioChange">
              <a-radio :value="1">是</a-radio>
              <a-radio :value="2">否</a-radio>
            </a-radio-group>
          </a-form-model-item>
          <template v-if="formData.hasTeam == 1">
            <a-form-model-item
              v-for="(item, idx) in formData.teamList"
              prop="tt"
              :key="idx + 1 + 'tt'"
              :label="idx == 0 ? '团体' : ''"
              v-bind="
                idx === 0
                  ? layout
                  : {
                      wrapperCol: {
                        xs: { span: 24, offset: 0 },
                        sm: { span: 18, offset: 6 }
                      }
                    }
              "
            >
              <a-input-group compact>
                <!--               <a-select style="width: 32%" :maxTagTextLength='90' :maxTagCount='1' placeholder="请选择团体" mode="multiple" v-model="formData.team">
                <a-select-option v-for="i in teamList" :key="i.id">
                  {{ i.originName }}
                </a-select-option>
              </a-select> -->
                <a-select
                  style="width: 52%"
                  placeholder="请选择团体"
                  @change="value => teamChange(value, item)"
                  v-model="item.team"
                >
                  <div slot="dropdownRender" slot-scope="menu">
                    <v-nodes :vnodes="menu" />
                    <a-divider style="margin: 4px 0;" />
                    <div
                      style="padding: 4px 8px; cursor: pointer;"
                      @mousedown="e => e.preventDefault()"
                      @click="openTeam"
                    >
                      <a-icon type="plus" /> 新建团体
                    </div>
                  </div>
                  <a-select-option v-for="i in teamList" :value="i.id" :key="i.id">
                    {{ i.originName }}/{{ i.processedName }}
                  </a-select-option>
                </a-select>
                <a-input style="width: 48%" v-model="item.teamDescription" placeholder="请输入在团体的职责" />
              </a-input-group>
              <div class="end-btns" style="top:-14px;">
                <a-button
                  class="item-btn"
                  :disabled="formData.teamList.length <= 1"
                  shape="circle"
                  size="small"
                  icon="minus"
                  @click="delTeam(formData.teamList, idx)"
                />
                <a-button
                  class="item-btn"
                  shape="circle"
                  size="small"
                  icon="plus"
                  @click="addTeam(formData.teamList, idx)"
                />
              </div>
            </a-form-model-item>
          </template>

          <!--           <a-form-model-item
            v-for="(item, index) in formData.gxList"
            :key="index + 1 + 'gx'"
            :label="index == 0 ? '关系' : ''"
            v-bind="
              index === 0
                ? layout
                : {
                    wrapperCol: {
                      xs: { span: 24, offset: 0 },
                      sm: { span: 18, offset: 6 }
                    }
                  }
            "
          >
            <a-input-group compact>
              <a-cascader
                style="width: 32%"
                :options="roleList"
                @change="(value, option) => onChange(value, option, item)"
              >
                <a href="#" style="line-height:32px">
                  <span v-if="item.gx">
                    <span v-if="item.gx.indexOf('left') > -1">
                      {{ item.gx.substr(0, item.gx.indexOf('left')) }}
                      <a-icon type="swap-left" />
                      {{ item.gx.substr(item.gx.indexOf('left') + 4) }}
                    </span>
                    <span v-else-if="item.gx.indexOf('right') > -1">
                      {{ item.gx.substr(0, item.gx.indexOf('right')) }}
                      <a-icon type="swap-right" />
                      {{ item.gx.substr(item.gx.indexOf('right') + 5) }}
                    </span>
                    <span v-else-if="item.gx.indexOf('swap') > -1">
                      {{ item.gx.substr(0, item.gx.indexOf('swap')) }}
                      <a-icon type="swap" />
                      {{ item.gx.substr(item.gx.indexOf('swap') + 4) }}
                    </span>
                  </span>
                  <span v-else>
                    请选择关系
                  </span>
                </a>
              </a-cascader>
              <a-input style="width: 68%" v-model="item.name" placeholder="请输入关系描述"></a-input>
            </a-input-group>
            <a-radio-group v-model="item.honorific" @change="value => radioChange(value, item)" style="width:32%">
              <a-radio value="敬语">敬语</a-radio>
              <a-radio value="平语">平语</a-radio>
            </a-radio-group>
            <a-input style="width: 68%" v-model="item.call" placeholder="请输入称呼描述"></a-input>
            <div class="end-btns" style="top:-12px">
              <a-button
                class="item-btn"
                :disabled="formData.gxList.length <= 1"
                shape="circle"
                size="small"
                icon="minus"
                @click="delItem(formData.gxList, index)"
              />
              <a-button
                class="item-btn"
                shape="circle"
                size="small"
                icon="plus"
                @click="addItem(formData.gxList, index)"
              />
            </div>
          </a-form-model-item> -->
          <div class="other-item" @click="openOher('top')">
            <a-icon
              :class="showTop && formData.gxList && formData.gxList.length ? 'open-oher-item' : 'close-oher-item'"
              type="right"
            />
            关系
          </div>
          <div v-if="showTop && formData.gxList && formData.gxList.length" class="gx-list-show">
            <div style="color:red">
              注:填写单一关系。<br />
              例，此术语表是小明的术语表，小红是小明的妹妹，此时添加关系时，人物选择“小红”，关系填入“妹妹”，称呼填入小明对小红的称呼即可。
            </div>
            <div class="gx-list">
              <a-row style="font-weight:700">
                <a-col :span="6">人物</a-col>
                <a-col :span="5">关系</a-col>
                <a-col :span="5">敬语/平语</a-col>
                <a-col :span="8"
                  >称呼
                  <div class="end-btns-table" v-if="!formData.gxList || (formData.gxList && !formData.gxList.length)">
                    <a-button
                      class="item-btn"
                      :disabled="true"
                      shape="circle"
                      size="small"
                      icon="minus"
                      @click="delTeam(formData.gxList, index)"
                    />
                    <a-button
                      class="item-btn"
                      shape="circle"
                      size="small"
                      icon="plus"
                      @click="addgx(formData.gxList, -1)"
                    /></div
                ></a-col>
              </a-row>
              <a-row class="gx-row" v-for="(item, index) in formData.gxList" :key="index + 1 + 'gx'">
                <a-col @click="editGxItem(formData.gxList, index)" :span="6">{{ item.processedName || '' }}</a-col>
                <a-col @click="editGxItem(formData.gxList, index)" :span="5">{{ item.name || '' }}</a-col>
                <a-col @click="editGxItem(formData.gxList, index)" :span="5">{{
                  item.honorific
                    ? item.honorific == '其他'
                      ? `其他${item.elseInfo ? '，' + item.elseInfo : ''}`
                      : item.honorific
                    : ''
                }}</a-col>
                <a-col @click="editGxItem(formData.gxList, index)" :span="8"
                  >{{ item.call }}
                  <div class="end-btns-table">
                    <a-button
                      class="item-btn"
                      shape="circle"
                      size="small"
                      icon="minus"
                      @click.stop="delTeam(formData.gxList, index)"
                    />
                    <a-button
                      class="item-btn"
                      shape="circle"
                      size="small"
                      icon="plus"
                      @click.stop="addgx(formData.gxList, index)"
                    /></div
                ></a-col>
              </a-row>
            </div>
          </div>
          <div class="other-item" @click="openOher('bottom')">
            <a-icon :class="showBottom ? 'open-oher-item' : 'close-oher-item'" type="right" />
            其他选填内容
          </div>
          <a-form-model-item v-if="showBottom" label="职称" prop="jobTitle">
            <a-input v-model="formData.jobTitle" placeholder="请输入内容"></a-input>
          </a-form-model-item>
          <a-form-model-item v-if="showBottom" label="公司名称" prop="companyName">
            <a-input v-model="formData.companyName" placeholder="请输入内容"></a-input>
          </a-form-model-item>
          <a-form-model-item v-if="showBottom" label="爱称（褒义）" prop="goodNickname">
            <a-input v-model="formData.goodNickname" placeholder="请输入内容"></a-input>
          </a-form-model-item>
          <a-form-model-item v-if="showBottom" label="外号（贬义）" prop="badNickname">
            <a-input v-model="formData.badNickname" placeholder="请输入内容"></a-input>
          </a-form-model-item>
          <a-form-model-item v-if="showBottom" label="邮箱" prop="email">
            <a-input v-model="formData.email" placeholder="请输入内容"></a-input>
          </a-form-model-item>
          <a-form-model-item v-if="showBottom" label="游戏ID" prop="gameAccount">
            <a-input v-model="formData.gameAccount" placeholder="请输入内容"></a-input>
          </a-form-model-item>
          <a-form-model-item v-if="showBottom" label="手机号" prop="mobile">
            <a-input v-model="formData.mobile" placeholder="请输入内容"></a-input>
          </a-form-model-item>
          <a-form-model-item v-if="showBottom" label="学校" prop="school">
            <a-input v-model="formData.school" placeholder="请输入内容"></a-input>
          </a-form-model-item>
          <a-form-model-item v-if="showBottom" label="班级" prop="clazz">
            <a-input v-model="formData.clazz" placeholder="请输入内容"></a-input>
          </a-form-model-item>
          <a-form-model-item v-if="showBottom" label="武器" prop="weapon">
            <a-input v-model="formData.weapon" placeholder="请输入内容"></a-input>
          </a-form-model-item>
        </template>
        <template v-if="formType == '团体'">
          <a-form-model-item label="原文名" prop="originName">
            <a-input v-model="formData.originName" placeholder="请输入原文名"></a-input>
            <div
              class="check-origin"
              v-if="formData.originName && terms.filter(w => formData.originName.indexOf(w.value) > -1).length"
            >
              <div v-for="word in terms.filter(w => formData.originName == w.value)" :key="word.id">
                <span @click.stop="openTerm(word)">{{ word.value + '→' + word.target }}</span>
              </div>
            </div>
          </a-form-model-item>
          <a-form-model-item label="译文名（全名）" prop="processedName" class="phonetic-item">
            <span>
              <a-input v-model="formData.processedName" style="width:200px" placeholder="请输入译文名"></a-input>
              <span style="margin-left:5px;" v-if="formData.phoneticTextList && formData.phoneticTextList.length">
                {{ formData.phoneticTextList.map(p => p.value).join('') }}
              </span>
            </span>
            <a-button
              :loading="inputLoading"
              size="small"
              style="margin-left:5px;"
              v-if="departName == '日语部'"
              @click="addPhonetic(formData.processedName, 'processedName')"
              >获取注音</a-button
            >
            <a-button
              size="small"
              style="margin-left:5px;"
              @click="recommendText(formData.originName, 'originNickname')"
              v-if="departName.indexOf('韩语部') > -1"
              >查看推荐用字</a-button
            >
          </a-form-model-item>
          <a-form-model-item
            v-if="departName.indexOf('日语部') > -1"
            :disabled="!checkPermission('phoneticStatus:edit') && isEdit"
            label="注音情况"
            prop="processedName"
          >
            <a-radio-group v-model="formData.phoneticStatus">
              <a-radio style="height:30px" :value="0">未注音</a-radio>
              <a-radio style="height:30px" :value="1">已注音</a-radio>
              <!--               <a-radio style="height:30px" :value="2">姓未注音</a-radio>
              <a-radio style="height:30px" :value="3">名未注音</a-radio> -->
              <a-radio style="height:30px" :value="4">每次注音</a-radio>
              <a-radio style="height:30px" :value="5">不需要注音</a-radio>
            </a-radio-group>
          </a-form-model-item>
          <!--           <a-form-model-item v-if="departName == '日语部'" label="注音情况" prop="processedName">
            <a-radio-group v-model="formData.autoPhoneticTrigger" style="line-height:40px">
              <a-radio :value="0">作品中每次</a-radio>
              <a-radio :value="1">作品中首次</a-radio>
              <a-radio :value="2">每话首次</a-radio>
              <br />
              <a-radio :value="3">每页首次</a-radio>
              <a-radio :value="4">人员校正</a-radio>
            </a-radio-group>
          </a-form-model-item> -->
          <a-form-model-item label="初次登场章节" prop="debut">
            <a-input-number :min="0" v-model="formData.debut" placeholder="请输入初次登场章节"></a-input-number>
          </a-form-model-item>
          <a-form-model-item label="说明" prop="description">
            <a-input v-model="formData.description" placeholder="限制五十字以内"></a-input>
          </a-form-model-item>
        </template>
      </a-form-model>
      <terms-cropper-model ref="termsCropperModel" @upImg="upImg"> </terms-cropper-model>
      <terms-team-modal
        ref="termsTeamModel"
        :departName="departName"
        :productionId="taskDetail.productionId"
        :parent="taskDetail"
        @afterSave="getTeamList"
      />
    </div>
    <div @mouseover="topCenterMouseover" class="term-form" v-else>
      <template v-if="curRow.term && curRow.term.termType == '人物'">
        <info-item :labelWidth="labelWidth" label="头像" style="padding-bottom:12px;">
          <div style="display:flex;align-items: center;position:relative">
            <div
              v-if="curRow.term && curRow.term.subjectImgUrl[0]"
              style="border:2px solid green;border-radius:50%;padding:5px;"
            >
              <div class="avatar-show">
                <img :src="curRow.term.subjectImgUrl[0]" style="width:90px;height:90px;" />
              </div>
            </div>
            <div v-else class="avatar-show">
              <img src="./../../../assets/tx.png" style="width:90px;height:90px" alt="" />
            </div>
            <div style="margin-left:20px" v-if="curRow.term && curRow.term.subjectImgUrl[1]" class="avatar-show">
              <img :src="curRow.term.subjectImgUrl[1]" style="width:90px;height:90px" />
            </div>
            <div v-else class="avatar-show" style="margin-left:20px">
              <img src="./../../../assets/tx.png" style="width:90px;height:90px" alt="" />
            </div>
            <div style="margin-left:20px" v-if="curRow.term && curRow.term.subjectImgUrl[2]" class="avatar-show">
              <img :src="curRow.term.subjectImgUrl[2]" style="width:90px;height:90px" />
            </div>
            <div v-else class="avatar-show" style="margin-left:20px">
              <img src="./../../../assets/tx.png" style="width:90px;height:90px" alt="" />
            </div>
          </div>
        </info-item>
        <div class="other-item">
          原文名
        </div>
        <!--         <info-item :labelWidth="labelWidth" label="原文名">
          {{ curRow.term.originName }}
        </info-item> -->
        <info-item :labelWidth="labelWidth" label="姓">
          {{ curRow.term.originFamilyName }}
        </info-item>
        <info-item :labelWidth="labelWidth" label="名">
          {{ curRow.term.originGivenName }}
        </info-item>
        <div class="other-item">
          <!-- <a-icon :class="'open-oher-item'" type="right" /> -->
          译文名
        </div>
        <info-item :labelWidth="labelWidth" label="姓">
          {{ curRow.term.processedFamilyName }}
          <span
            style="margin-left:12px;"
            v-if="curRow.term.phoneticFamilyNameTextList && curRow.term.phoneticFamilyNameTextList.length"
          >
            {{ curRow.term.phoneticFamilyNameTextList.map(p => p.value).join('') }}
          </span>
        </info-item>
        <info-item :labelWidth="labelWidth" label="名">
          {{ curRow.term.processedGivenName }}
          <span
            style="margin-left:12px;"
            v-if="curRow.term.phoneticGivenNameTextList && curRow.term.phoneticGivenNameTextList.length"
          >
            {{ curRow.term.phoneticGivenNameTextList.map(p => p.value).join('') }}
          </span>
        </info-item>
        <info-item :labelWidth="labelWidth" label="略称（小名）">
          {{ curRow.term.originNickname }}
          <span
            style="margin-left:12px;"
            v-if="curRow.term.phoneticNickNameTextList && curRow.term.phoneticNickNameTextList.length"
          >
            {{ curRow.term.phoneticNickNameTextList.map(p => p.value).join('') }}
          </span>
        </info-item>
        <!--         <info-item v-if="departName.indexOf('韩语部') > -1" :labelWidth="labelWidth" label="译文名（全名）">
          {{ curRow.term.processedName }}
          <span style="margin-left:12px;" v-if="curRow.term.phoneticTextList && curRow.term.phoneticTextList.length">
            {{ curRow.term.phoneticTextList.map(p => p.value).join('') }}
          </span>
        </info-item> -->
        <!--         <info-item :labelWidth="labelWidth" label="译文名（全名）">
          {{ curRow.term.processedName }}
        </info-item> -->
        <!--         <info-item v-if="departName == '日语部'" :labelWidth="labelWidth" label="注音">
          {{ curRow.term.phoneticText }}
        </info-item> -->
        <info-item v-if="departName == '日语部'" :labelWidth="labelWidth" label="注音情况">
          {{
            curRow.term.phoneticStatus == 0
              ? '未注音'
              : curRow.term.phoneticStatus == 1
              ? '已注音'
              : curRow.term.phoneticStatus == 2
              ? '姓未注音'
              : curRow.term.phoneticStatus == 3
              ? '名未注音'
              : curRow.term.phoneticStatus == 4
              ? '每次注音'
              : curRow.term.phoneticStatus == 5
              ? '不需要注音'
              : ''
          }}
        </info-item>
        <!--         <info-item v-if="departName == '日语部'" :labelWidth="labelWidth" label="注音情况">
          {{
            curRow.term.autoPhoneticTrigger == 0
              ? '作品中每次'
              : curRow.term.autoPhoneticTrigger == 1
              ? '作品中首次'
              : curRow.term.autoPhoneticTrigger == 2
              ? '每话首次'
              : curRow.term.autoPhoneticTrigger == 3
              ? '每页首次'
              : curRow.term.autoPhoneticTrigger == 4
              ? '人员校正'
              : ''
          }}
        </info-item> -->
        <!--         <info-item v-if="departName.indexOf('韩语部') > -1" :labelWidth="labelWidth" label="略称（小名）">
          {{ curRow.term.originNickname }}
        </info-item> -->
        <info-item :labelWidth="labelWidth" label="初次登场章节">
          {{ curRow.term.debut }}
        </info-item>
        <info-item :labelWidth="labelWidth" label="性别">
          {{ curRow.term.gender }}
        </info-item>
        <info-item :labelWidth="labelWidth" label="身份（职业）">
          {{ curRow.term.identity }}
        </info-item>
        <info-item :labelWidth="labelWidth" label="自称">
          {{ curRow.term.call }}
        </info-item>
        <info-item :labelWidth="labelWidth" label="语气">
          {{ curRow.term.tone }}
        </info-item>
        <info-item :labelWidth="labelWidth" label="性格">
          <div style="display:flex;" v-for="(i, idx) in curRow.term.characters" :key="idx + 1 + 'xgShow'">
            <div style="width:32%">{{ i.name }}</div>
            <div style="width:68%">{{ i.description }}</div>
          </div>
        </info-item>
        <info-item :labelWidth="labelWidth" label="说明">
          {{ curRow.term.description }}
        </info-item>
        <template v-if="curRow.relations['团体关系']">
          <info-item :labelWidth="labelWidth" label="团体">
            <div style="display:flex;" v-for="(i, idx) in curRow.relations['团体关系']" :key="idx + 1 + 'ttShow'">
              <div style="width:32%">{{ i.originName }}</div>
              <div style="width:68%">{{ i.fromOtherInfo.call }}</div>
            </div>
          </info-item>
        </template>
        <!--         <template v-if="curRow.relations['人物关系']">
          <info-item :labelWidth="labelWidth" label="关系">
            <div
              style="display:flex;margin-bottom:10px"
              v-for="(i, idx) in curRow.relations['人物关系']"
              :key="idx + 1 + 'gxShow'"
            >
              <div style="width:20%">
                <div class="avatar-show-mini">
                  <img v-if="i.subjectImgUrl" :src="i.subjectImgUrl" style="width:40px;height:40px" alt="" />
                  <div v-else style="text-align:center">{{ i.originName }}</div>
                </div>
              </div>
              <div style="width:80%">
                <div v-if="i.typeDesc == 'left'">
                  <span
                    >{{ i.processedName }} <a-icon type="swap-right" /> {{ curRow.term.processedName }}的关系：</span
                  >
                  <span>{{ i.name }}</span>
                </div>
                <div v-else>
                  <span>{{ i.processedName }} <a-icon type="swap-left" /> {{ curRow.term.processedName }}的关系：</span>
                  <span>{{ i.name }}</span>
                </div>
                <div v-if="i.typeDesc == 'right'">
                  <span>{{ i.otherInfo.honorific }}</span>
                  <span> | </span>
                  <span>{{ i.otherInfo.call }}</span>
                </div>
                <div v-else>
                  <span>{{ i.fromOtherInfo.honorific }}</span>
                  <span> | </span>
                  <span>{{ i.fromOtherInfo.call }}</span>
                </div>
              </div>
            </div>
          </info-item>
        </template> -->
        <div
          class="other-item"
          v-if="curRow.relations['人物关系'] && curRow.relations['人物关系'] && curRow.relations['人物关系'].length"
          @click="openOher('curTop')"
        >
          <a-icon :class="showCurTop ? 'open-oher-item' : 'close-oher-item'" type="right" />
          关系
        </div>
        <div v-if="showCurTop && curRow.relations['人物关系'] && curRow.relations['人物关系'].length">
          <div></div>
          <div class="gx-list">
            <a-row style="font-weight:700">
              <a-col :span="6">人物</a-col>
              <a-col :span="5">关系</a-col>
              <a-col :span="5">敬语/平语</a-col>
              <a-col :span="8">称呼</a-col>
            </a-row>
            <a-row v-for="(item, index) in curRow.relations['人物关系']" :key="index + 1 + 'gx'">
              <a-col :span="6">
                <img class="avatar-show-mini" :src="item.subjectImgUrl || './../../../assets/tx.png'" alt="" />
                <span style="margin-left:15px;">{{ item.processedName || '' }}</span>
              </a-col>
              <a-col :span="5">{{ item.name || '' }}</a-col>
              <a-col :span="5" v-if="item.typeDesc == 'left'">{{
                item.fromOtherInfo && item.fromOtherInfo.honorific
                  ? item.fromOtherInfo.honorific == '其他'
                    ? `其他${item.elseInfo ? '，' + item.elseInfo : ''}`
                    : item.fromOtherInfo.honorific
                  : ''
              }}</a-col>
              <a-col :span="5" v-else>{{
                item.otherInfo && item.otherInfo.honorific
                  ? item.otherInfo.honorific == '其他'
                    ? `其他${item.elseInfo ? '，' + item.elseInfo : ''}`
                    : item.otherInfo.honorific
                  : ''
              }}</a-col>
              <a-col v-if="item.typeDesc == 'left'" :span="8">{{
                item.fromOtherInfo && item.fromOtherInfo.call ? item.fromOtherInfo.call : ''
              }}</a-col>
              <a-col v-else :span="8">{{ item.otherInfo && item.otherInfo.call ? item.otherInfo.call : '' }}</a-col>
            </a-row>
          </div>
        </div>
        <div class="other-item" @click="openOher('curBottom')">
          <a-icon :class="showCurBottom ? 'open-oher-item' : 'close-oher-item'" type="right" />
          其他选填内容
        </div>
        <info-item v-if="showCurBottom" :labelWidth="labelWidth" label="职称">
          {{ curRow.term.jobTitle }}
        </info-item>
        <info-item v-if="showCurBottom" :labelWidth="labelWidth" label="公司名称">
          {{ curRow.term.companyName }}
        </info-item>
        <info-item v-if="showCurBottom" :labelWidth="labelWidth" label="爱称（褒义）">
          {{ curRow.term.goodNickname }}
        </info-item>
        <info-item v-if="showCurBottom" :labelWidth="labelWidth" label="外号（贬义）">
          {{ curRow.term.badNickname }}
        </info-item>
        <info-item v-if="showCurBottom" :labelWidth="labelWidth" label="邮箱">
          {{ curRow.term.email }}
        </info-item>
        <info-item v-if="showCurBottom" :labelWidth="labelWidth" label="游戏ID">
          {{ curRow.term.gameAccount }}
        </info-item>
        <info-item v-if="showCurBottom" :labelWidth="labelWidth" label="手机号">
          {{ curRow.term.mobile }}
        </info-item>
        <info-item v-if="showCurBottom" :labelWidth="labelWidth" label="学校">
          {{ curRow.term.school }}
        </info-item>
        <info-item v-if="showCurBottom" :labelWidth="labelWidth" label="班级">
          {{ curRow.term.clazz }}
        </info-item>
        <info-item v-if="showCurBottom" :labelWidth="labelWidth" label="武器">
          {{ curRow.term.weapon }}
        </info-item>
      </template>
      <template v-else-if="curRow.term && curRow.term.termType == '团体'">
        <info-item :labelWidth="labelWidth" label="原文名">
          {{ curRow.term.originName }}
        </info-item>
        <info-item :labelWidth="labelWidth" label="译文名（全名）">
          {{ curRow.term.processedName }}
          <span style="margin-left:12px;" v-if="curRow.term.phoneticTextList && curRow.term.phoneticTextList.length">
            {{ curRow.term.phoneticTextList.map(p => p.value).join('') }}
          </span>
        </info-item>
        <info-item v-if="departName == '日语部'" :labelWidth="labelWidth" label="注音情况">
          {{
            curRow.term.phoneticStatus == 0
              ? '未注音'
              : curRow.term.phoneticStatus == 1
              ? '已注音'
              : curRow.term.phoneticStatus == 2
              ? '姓未注音'
              : curRow.term.phoneticStatus == 3
              ? '名未注音'
              : curRow.term.phoneticStatus == 4
              ? '每次注音'
              : curRow.term.phoneticStatus == 5
              ? '不需要注音'
              : ''
          }}
        </info-item>
        <!--         <info-item v-if="departName == '日语部'" :labelWidth="labelWidth" label="注音情况">
          {{
            curRow.term.autoPhoneticTrigger == 0
              ? '作品中每次'
              : curRow.term.autoPhoneticTrigger == 1
              ? '作品中首次'
              : curRow.term.autoPhoneticTrigger == 2
              ? '每话首次'
              : curRow.term.autoPhoneticTrigger == 3
              ? '每页首次'
              : curRow.term.autoPhoneticTrigger == 4
              ? '人员校正'
              : ''
          }}
        </info-item> -->
        <info-item :labelWidth="labelWidth" label="初次登场章节">
          {{ curRow.term.debut }}
        </info-item>
        <info-item :labelWidth="labelWidth" label="说明">
          {{ curRow.term.description }}
        </info-item>
      </template>
    </div>
    <template slot="footer">
      <div @mouseover="topCenterMouseover" style="float:left" :style="backCss">
        <span
          class="reject-button reject"
          style="margin-right:5px;"
          v-if="!isAddTerm && !isEdit && curRow && curRow.term && curRow.term.status != '已驳回'"
          @click="reject(curRow)"
          >驳回</span
        >
        <span
          class="reject-button send"
          v-if="!isAddTerm && !isEdit && curRow && curRow.term && curRow.term.status != '已确认'"
          @click="send(curRow)"
          >确认</span
        >
      </div>
      <a-button @mouseover="topCenterMouseover" v-if="!isAddTerm && isEdit" key="back" @click="noEdit">
        取消
      </a-button>
      <a-button @mouseover="topCenterMouseover" v-if="!isAddTerm && !isEdit" key="back" @click="handleCancel">
        关闭
      </a-button>
      <a-button @mouseover="topCenterMouseover" v-if="!isEdit" @click="edit" type="primary">
        编辑
      </a-button>
      <a-button
        @mouseover="topCenterMouseover"
        v-if="!isAddTerm && isEdit"
        key="submit"
        type="primary"
        @click="handleOk"
      >
        确定
      </a-button>
      <a-button @mouseover="topCenterMouseover" v-if="isAddTerm" key="back" @click="handleCancel">
        关闭
      </a-button>
    </template>
    <a-modal title="驳回理由" :zIndex="9000" :visible="rejectVisible" @ok="handleRejectOk" @cancel="handleRejectCancel">
      <a-input v-model="rejectReason" placeholder="请输入驳回理由"></a-input>
    </a-modal>
    <terms-gx-modal ref="termsGxModel" :roleList="roleList" @saveGx="saveGx" @editGx="editGx" />
    <terms-phonetic-modal ref="termsPhoneticModal" @savePhonetic="savePhonetic" />
    <terms-recommend-modal ref="termsRecommendModal" :parent="taskDetail" @saveRecommend="saveRecommend" />
  </a-modal>
</template>

<script>
import Vue from 'vue'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { getAction, postAction } from '@/api/manage'
import { checkPermission } from '@/utils/hasPermission'
import axios from 'axios'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import termsCropperModel from '@/views/production/details/terms-cropper-model'
import TermsTeamModal from '@/views/production/details/terms-team-modal'
import { UploadFileToOssMixins } from '@/mixins/UploadFileToOssMixins'
import TermsGxModal from '@/views/production/details/terms-gx-modal'
import termsPhoneticModal from '@/views/production/details/terms-phonetic-modal'
import termsRecommendModal from '@/views/production/details/terms-recommend-modal'
export default {
  mixins: [UploadFileToOssMixins],
  components: {
    termsCropperModel,
    TermsTeamModal,
    TermsGxModal,
    termsPhoneticModal,
    termsRecommendModal,
    Editor,
    Toolbar,
    VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes
    }
  },
  props: {
    taskDetail: {
      type: Object,
      default: () => ({})
    },
    isAddTerm: {
      type: Boolean,
      default: false
    },
    selectTextAreaData: {
      type: Array,
      default: () => []
    },
    isDepart: {
      type: Boolean,
      default: false
    },
    departName: {
      type: String,
      default: ''
    },
    terms: {
      type: Array,
      default: () => []
    },
    termsAll: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      curRow: {
        term: null
      },
      visible: false,
      rejectVisible: false,
      rejectReason: '',
      rules: {
        termTypeName: [{ required: true, message: '请选择分类', trigger: 'change' }],
        originName: [{ required: true, message: '请输入原文名', trigger: 'blur' }],
        debut: [{ required: true, message: '请输入初次登场章节', trigger: 'blur' }],
        /*        gender: [{ required: true, message: '请选择性别', trigger: 'change' }],
        identity: [{ required: true, message: '请输入身份（职业）', trigger: 'blur' }],
        description: [{ required: true, message: '请输入说明', trigger: 'blur' }], */
        originAll: [{ validator: this.valiOriginAll }]
        /* characters: [{ required: true, validator: this.validateCharacters }] */
      },
      formType: '',
      templateNameList: [],
      labelWidth: '145',
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 }
      },
      teamList: [],
      roleList: [],
      uploadIndex: 0,
      isEdit: false,
      avatarLoading: false,
      labelWidth: '145',
      formData: {
        subjectImgUrl: ['', '', ''],
        phoneticFamilyNameTextList: [],
        phoneticGivenNameTextList: [],
        phoneticNickNameTextList: [],
        phoneticTextList: [],
        phoneticText: '',
        phoneticStatus: 0,
        autoPhoneticTrigger: 0,
        gxList: [],
        teamList: [
          {
            team: undefined,
            teamDescription: ''
          }
        ],
        hasTeam: 2,
        characters: [
          {
            name: '',
            description: ''
          }
        ]
      },
      showBottom: false,
      showTop: true,
      showCurTop: true,
      showCurBottom: false,
      inputLoading: false,
      deleteRelations: [],
      editor: null,
      editor2: null,
      editor3: null,
      copyUrl1: '',
      copyUrl2: '',
      copyUrl3: '',
      mode: 'default',
      editorConfig: { placeholder: '粘贴文件', MENU_CONF: { uploadImage: null } },
      editorConfig2: { placeholder: '粘贴文件', MENU_CONF: { uploadImage: null } },
      editorConfig3: { placeholder: '粘贴文件', MENU_CONF: { uploadImage: null } }
    }
  },
  watch: {
    copyUrl1() {
      this.editor.clear()
    },
    copyUrl2() {
      this.editor2.clear()
    },
    copyUrl3() {
      this.editor3.clear()
    }
  },
  computed: {
    backCss() {
      let borderColor = this.$store.state.app.color
      return {
        '--theme-color': borderColor
      }
    }
  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  },
  methods: {
    topCenterMouseover() {
      this.$emit('topCenterMouseover')
    },
    openTerm(word) {
      let record = this.termsAll.find(t => t.term.id == word.id)
      this.$emit('openEditTermModal', record)
      this.$emit('changeIsAddTerm', true)
    },
    async next() {
      if (this.curRow && this.curRow.hasNext) {
        const res = await getAction(
          '/term/get/current/next?currentTermId=' +
            this.curRow.term.id +
            '&productionId=' +
            this.taskDetail.productionId
        )
        if (res.code == 200) {
          this.curRow = res.data.records[0]
          this.$forceUpdate()
        } else {
          this.$message.error(res.msg)
        }
      }
    },
    async prev() {
      if (this.curRow && this.curRow.hasLast) {
        const res = await getAction(
          '/term/get/current/last?currentTermId=' +
            this.curRow.term.id +
            '&productionId=' +
            this.taskDetail.productionId
        )
        if (res.code == 200) {
          this.curRow = res.data.records[0]
          this.$forceUpdate()
        } else {
          this.$message.error(res.msg)
        }
      }
    },
    handleRejectCancel() {
      this.rejectVisible = false
      this.rejectReason = ''
    },
    async handleRejectOk() {
      const res = await postAction(
        '/term/confirm?ids=' +
          this.curRow.term.id +
          '&operation=UP&productionId=' +
          this.taskDetail.productionId +
          '&rejectReason=' +
          this.rejectReason
      )
      if (res.code == 200) {
        this.curRow.term.status = '已驳回'
        this.$message.success('驳回成功')
      } else {
        this.$message.error(res.msg)
      }
      this.rejectVisible = false
      this.rejectReason = ''
    },
    reject() {
      this.rejectVisible = true
    },
    async send(record) {
      const res = await postAction(
        '/term/confirm?ids=' + record.term.id + '&operation=DOWN&productionId=' + this.taskDetail.productionId
      )
      if (res.code == 200) {
        record.term.status = '已确认'
        this.$message.success('已确认')
      } else {
        this.$message.error(res.msg)
      }
    },
    valiOriginAll(rule, value, callback) {
      if (!this.formData.originFamilyName && !this.formData.originGivenName) {
        callback('请输入内容')
      }
      callback()
    },
    originNameChange() {
      this.formData.originName = (this.formData.originFamilyName || '') + (this.formData.originGivenName || '')
    },
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
      if (this.copyUrl1) {
        this.editor.clear()
        this.editor.updateView()
      }
    },
    onCreated2(editor) {
      this.editor2 = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
      if (this.copyUrl2) {
        this.editor2.clear()
      }
    },
    onCreated3(editor) {
      this.editor3 = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
      if (this.copyUrl3) {
        this.editor3.clear()
      }
    },
    customPaste(editor, event, callback) {
      console.log('ClipboardEvent 粘贴事件对象', event)
      // const html = event.clipboardData.getData('text/html') // 获取粘贴的 html
      // const text = event.clipboardData.getData('text/plain') // 获取粘贴的纯文本
      // const rtf = event.clipboardData.getData('text/rtf') // 获取 rtf 数据（如从 word wsp 复制粘贴）

      // 自定义插入内容
      /* editor.insertText('xxx') */

      // 返回 false ，阻止默认粘贴行为
      let items = event.clipboardData && event.clipboardData.items
      let file = null
      if (items && items.length) {
        // 检索剪切板items
        if (items[0].kind == 'file') {
          file = items[0].getAsFile()
          console.log(file)
          this.beforeCroUpload(file, 0)
        } else {
          this.$message.error('粘贴内容不是文件内容，请重新复制后粘贴')
        }
      }
      event.preventDefault()
      callback(false) // 返回值（注意，vue 事件的返回值，不能用 return）

      // 返回 true ，继续默认的粘贴行为
      // callback(true)
    },
    customPaste2(editor, event, callback) {
      console.log('ClipboardEvent 粘贴事件对象', event)
      // const html = event.clipboardData.getData('text/html') // 获取粘贴的 html
      // const text = event.clipboardData.getData('text/plain') // 获取粘贴的纯文本
      // const rtf = event.clipboardData.getData('text/rtf') // 获取 rtf 数据（如从 word wsp 复制粘贴）

      // 自定义插入内容
      /* editor.insertText('xxx') */

      // 返回 false ，阻止默认粘贴行为
      let items = event.clipboardData && event.clipboardData.items
      let file = null
      if (items && items.length) {
        // 检索剪切板items
        if (items[0].kind == 'file') {
          file = items[0].getAsFile()
          console.log(file)
          this.beforeCroUpload(file, 1)
        } else {
          this.$message.error('粘贴内容不是文件内容，请重新复制后粘贴')
        }
      }
      event.preventDefault()
      callback(false) // 返回值（注意，vue 事件的返回值，不能用 return）

      // 返回 true ，继续默认的粘贴行为
      // callback(true)
    },
    customPaste3(editor, event, callback) {
      console.log('ClipboardEvent 粘贴事件对象', event)
      // const html = event.clipboardData.getData('text/html') // 获取粘贴的 html
      // const text = event.clipboardData.getData('text/plain') // 获取粘贴的纯文本
      // const rtf = event.clipboardData.getData('text/rtf') // 获取 rtf 数据（如从 word wsp 复制粘贴）

      // 自定义插入内容
      /* editor.insertText('xxx') */

      // 返回 false ，阻止默认粘贴行为
      let items = event.clipboardData && event.clipboardData.items
      let file = null
      if (items && items.length) {
        // 检索剪切板items
        if (items[0].kind == 'file') {
          file = items[0].getAsFile()
          console.log(file)
          this.beforeCroUpload(file, 2)
        } else {
          this.$message.error('粘贴内容不是文件内容，请重新复制后粘贴')
        }
      }
      event.preventDefault()
      callback(false) // 返回值（注意，vue 事件的返回值，不能用 return）

      // 返回 true ，继续默认的粘贴行为
      // callback(true)
    },
    clearUrl(index) {
      this.formData.subjectImgUrl[index] = ''
      this.$forceUpdate()
    },
    noEdit() {
      this.isEdit = false
    },
    async recommendText(text, type) {
      if (!text) return this.$message.error('清输入原文')
      this.inputLoading = true
      const res = await getAction('/guide/list', {
        current: 1,
        fieId: 'guidName',
        size: -1,
        typeId: 4
      })
      this.inputLoading = false
      //陈李说的一个平台只有一条推荐用字数据
      if (res.code == 200) {
        let arr = res.data.records.filter(item => item.classId == 16)
        let recommendTextData = arr.find(item =>
          item.departments.some(d => this.taskDetail.departmentIds.some(dId => dId == d.id))
        )
        if (recommendTextData) {
          this.$refs.termsRecommendModal.openModal(recommendTextData, text, type)
        } else {
          this.$refs.termsRecommendModal.openModal({ blacklist: [] }, text, type)
        }
      } else {
        this.$message.error(res.msg)
      }
    },
    async addPhonetic(text, type) {
      if (!text) return this.$message.error('请输入姓、名、略称')
      if (type == 'processedFamilyName' && this.formData.phoneticFamilyNameTextList.map(p => p.value).join('')) {
        this.$message.error('检测到修改过译文名，请注意检查注音是否正确')
        let data = [{ word: [] }]
        this.formData.phoneticFamilyNameTextList.forEach(item => {
          data[0].word.push({
            surface: item.key,
            roman: '',
            furigana: item.value
          })
        })
        this.$refs.termsPhoneticModal.openModal(
          data,
          text,
          type,
          true,
          this.formData.phoneticFamilyNameTextList[0].type
        )
      } else if (type == 'processedGivenName' && this.formData.phoneticGivenNameTextList.map(p => p.value).join('')) {
        this.$message.error('检测到修改过译文名，请注意检查注音是否正确')
        let data = [{ word: [] }]
        this.formData.phoneticGivenNameTextList.forEach(item => {
          data[0].word.push({
            surface: item.key,
            roman: '',
            furigana: item.value
          })
        })
        this.$refs.termsPhoneticModal.openModal(data, text, type, true, this.formData.phoneticGivenNameTextList[0].type)
      } else if (type == 'originNickname' && this.formData.phoneticNickNameTextList.map(p => p.value).join('')) {
        this.$message.error('检测到修改过译文名，请注意检查注音是否正确')
        let data = [{ word: [] }]
        this.formData.phoneticNickNameTextList.forEach(item => {
          data[0].word.push({
            surface: item.key,
            roman: '',
            furigana: item.value
          })
        })
        this.$refs.termsPhoneticModal.openModal(data, text, type, true, this.formData.phoneticNickNameTextList[0].type)
      } else if (type == 'processedName' && this.formData.phoneticTextList.map(p => p.value).join('')) {
        this.$message.error('检测到修改过译文名，请注意检查注音是否正确')
        let data = [{ word: [] }]
        this.formData.phoneticTextList.forEach(item => {
          data[0].word.push({
            surface: item.key,
            roman: '',
            furigana: item.value
          })
        })
        this.$refs.termsPhoneticModal.openModal(data, text, type, true, this.formData.phoneticTextList[0].type)
      } else {
        this.inputLoading = true
        const res = await getAction('/qualityInspection/questPhonetic', {
          sentence: text,
          type: 'single',
          grade: 1
        })
        if (res.code == 200) {
          this.$refs.termsPhoneticModal.openModal(res.data, text, type, false)
        } else {
          this.$message.error(res.msg)
        }
        this.inputLoading = false
      }
    },
    saveRecommend(data) {
      if (data.type == 'processedFamilyName') {
        this.formData.processedFamilyName = data.list.map(item => item.value).join('')
      } else if (data.type == 'processedGivenName') {
        this.formData.processedGivenName = data.list.map(item => item.value).join('')
      } else if (data.type == 'originNickname') {
        this.formData.originNickname = data.list.map(item => item.value).join('')
      } else if (data.type == 'processedName') {
        this.formData.processedName = data.list.map(item => item.value).join('')
      }
      this.$forceUpdate()
    },
    editGx(params) {
      this.formData.gxList[params.index].gx =
        this.roleList.find(r => r.value == params.anotherTermId).label +
        'left' +
        (this.formData.processedName || '无译文名')
      this.formData.gxList[params.index].honorific = params.honorific
      this.formData.gxList[params.index].call = params.call
      this.formData.gxList[params.index].elseInfo = params.elseInfo
      this.formData.gxList[params.index].name = params.name
      this.formData.gxList[params.index].anotherTermId = params.anotherTermId
      this.formData.gxList[params.index].processedName = this.roleList.find(r => r.value == params.anotherTermId).label
      this.$forceUpdate()
    },
    editGxItem(gxList, index) {
      this.$refs.termsGxModel.editModal(gxList, index)
    },
    phoneticTextInput() {
      if (this.formData.phoneticText) {
        this.formData.phoneticStatus = 1
      } else {
        this.formData.phoneticStatus = 0
      }
    },
    async processedNameBlur() {
      if (!this.formData.processedName) return
      this.inputLoading = true
      const res = await getAction('/qualityInspection/questPhonetic', {
        sentence: this.formData.processedName,
        type: 'multiple'
      })
      this.inputLoading = false
      if (res.code == 200) {
        if (res.data[0].result) {
          this.formData.phoneticText = res.data[0].result.word[0].furigana || ''
        } else {
          this.formData.phoneticText = res.data[0]?.word[0].furigana || ''
        }
        if (this.formData.phoneticText) {
          this.formData.phoneticStatus = 1
        } else {
          this.formData.phoneticStatus = 0
        }
      } else {
        this.$message.error(res.msg)
      }
    },
    addgx(gxList, index) {
      this.$refs.termsGxModel.openModal(gxList, index)
    },
    openOher(type) {
      if (type == 'bottom') {
        this.showBottom = !this.showBottom
      } else if (type == 'top') {
        this.showTop = !this.showTop
        if ((this.formData.gxList && !this.formData.gxList.length) || !this.formData.gxList) {
          if (!this.showTop) {
            this.showTop = true
          }
          this.formData.gxList = [
            {
              gx: '',
              name: '',
              honorific: '',
              call: ''
            }
          ]
          this.$forceUpdate()
        }
      } else if (type == 'curTop') {
        this.showCurTop = !this.showCurTop
      } else {
        this.showCurBottom = !this.showCurBottom
      }
    },
    open(record) {
      console.log({ record })
      this.visible = true
      this.curRow = record
      this.getTypeList()
      this.getTeamList()
      this.getRoleList()
      this.initE()
      this.initE2()
      this.initE3()
    },
    initE() {
      let that = this
      this.editorConfig.MENU_CONF['uploadImage'] = {
        // 自定义上传
        async customUpload(file, insertFn) {
          that
            .tokenAxios({
              url: '/oss',
              method: 'get'
            })
            .then(res => {
              let policy = {
                policy: res.data.policy,
                signature: res.data.signature,
                ossaccessKeyId: res.data.accessid,
                dir: res.data.dir,
                host: res.data.host,
                key: `${res.data.dir}${that.getGuid()}_${file.name.replace(/[^\u4e00-\u9fa5a-zA-Z0-9\w]/g, '')}`
              }
              that.ossUploadData = policy
              that.ossFilePolicy[file.name] = policy
              let formData = new FormData()
              for (let key in policy) {
                formData.append(key, policy[key])
              }
              formData.append('file', file)
              postAction(policy.host, formData).then(rest => {
                console.log('成功OSS', policy.host)
                let params = that.getSaveFileParams(file)
                that.formData.originImgUrl = params.filePath
                that.editor.undo()
                that.editor.dangerouslyInsertHtml(
                  `<img src='${that.formData.originImgUrl}' style='width:356px;height:auto' />`
                )
                that.isChange = true
                /* insertFn(policy.host, alt, href) */
              })
            })
        }
      }
    },
    initE2() {
      let that = this
      this.editorConfig2.MENU_CONF['uploadImage'] = {
        // 自定义上传
        async customUpload(file, insertFn) {
          that
            .tokenAxios({
              url: '/oss',
              method: 'get'
            })
            .then(res => {
              let policy = {
                policy: res.data.policy,
                signature: res.data.signature,
                ossaccessKeyId: res.data.accessid,
                dir: res.data.dir,
                host: res.data.host,
                key: `${res.data.dir}${that.getGuid()}_${file.name.replace(/[^\u4e00-\u9fa5a-zA-Z0-9\w]/g, '')}`
              }
              that.ossUploadData = policy
              that.ossFilePolicy[file.name] = policy
              let formData = new FormData()
              for (let key in policy) {
                formData.append(key, policy[key])
              }
              formData.append('file', file)
              postAction(policy.host, formData).then(rest => {
                console.log('成功OSS', policy.host)
                let params = that.getSaveFileParams(file)
                that.formData.processedImgUrl = params.filePath
                that.editor2.undo()
                that.editor2.dangerouslyInsertHtml(
                  `<img src='${that.formData.processedImgUrl}' style='width:356px;height:auto' />`
                )
                that.isChange2 = true
                /* insertFn(policy.host, alt, href) */
              })
            })
        }
      }
    },
    initE3() {
      let that = this
      this.editorConfig3.MENU_CONF['uploadImage'] = {
        // 自定义上传
        async customUpload(file, insertFn) {
          that
            .tokenAxios({
              url: '/oss',
              method: 'get'
            })
            .then(res => {
              let policy = {
                policy: res.data.policy,
                signature: res.data.signature,
                ossaccessKeyId: res.data.accessid,
                dir: res.data.dir,
                host: res.data.host,
                key: `${res.data.dir}${that.getGuid()}_${file.name.replace(/[^\u4e00-\u9fa5a-zA-Z0-9\w]/g, '')}`
              }
              that.ossUploadData = policy
              that.ossFilePolicy[file.name] = policy
              let formData = new FormData()
              for (let key in policy) {
                formData.append(key, policy[key])
              }
              formData.append('file', file)
              postAction(policy.host, formData).then(rest => {
                console.log('成功OSS', policy.host)
                let params = that.getSaveFileParams(file)
                that.formData.processedImgUrl = params.filePath
                that.editor2.undo()
                that.editor2.dangerouslyInsertHtml(
                  `<img src='${that.formData.processedImgUrl}' style='width:356px;height:auto' />`
                )
                that.isChange2 = true
                /* insertFn(policy.host, alt, href) */
              })
            })
        }
      }
    },
    handleOk() {
      this.save()
    },
    handleCancel() {
      this.visible = false
      this.isEdit = false
      this.initForm()
    },
    templateChange(value) {
      this.formType = this.templateNameList.find(item => item.name == value).type
    },
    async getTypeList() {
      const res = await getAction('/term/type/page?productionId=' + this.taskDetail.productionId)
      if (res.code == 200) {
        this.templateNameList = res.data.records.map(item => {
          return {
            type: item.termType.type,
            id: item.termType.id,
            name: item.termType.name
          }
        })
      }
    },
    async getTeamList() {
      const res = await getAction('/term/list/pretty?termTypeName=团体&productionId=' + this.taskDetail.productionId)
      if (res.code == 200) {
        this.teamList = res.data
      }
    },
    async getRoleList() {
      const res = await getAction('/term/list/pretty?&productionId=' + this.taskDetail.productionId)
      if (res.code == 200) {
        this.roleList = res.data
          ?.filter(item => item.termTypeName != '其它' && item.termTypeName != '专业术语')
          .map(item => {
            return {
              value: item.id,
              label: item.processedName,
              children: [
                {
                  value: 'right',
                  label: <a-icon type="swap-right" />
                },
                {
                  value: 'left',
                  label: <a-icon type="swap-left" />
                }
                /*               {
                value: 'swap',
                label: <a-icon type="swap" />
              } */
              ]
            }
          })
      }
    },
    checkPermission(value) {
      return checkPermission(value)
    },
    savePhonetic(data) {
      if (data.type == 'processedFamilyName') {
        this.formData.processedFamilyName = data.list.map(item => item.key).join('')
        this.formData.phoneticFamilyNameTextList = data.list
      } else if (data.type == 'processedGivenName') {
        this.formData.processedGivenName = data.list.map(item => item.key).join('')
        this.formData.phoneticGivenNameTextList = data.list
      } else if (data.type == 'originNickname') {
        this.formData.originNickname = data.list.map(item => item.key).join('')
        this.formData.phoneticNickNameTextList = data.list
      } else if (data.type == 'processedName') {
        this.formData.processedName = data.list.map(item => item.key).join('')
        this.formData.phoneticTextList = data.list
      }
      this.$forceUpdate()
    },
    addItem(list, index) {
      list = [
        ...list,
        {
          gx: '',
          name: '',
          honorific: '',
          call: ''
        }
      ]
      this.formData = Object.assign(this.formData, { gxList: list })
      this.$forceUpdate()
    },
    delItem(list, index) {
      list.splice(index, 1)
      this.$forceUpdate()
    },
    addTeam(list, index) {
      list.push({
        team: undefined,
        teamDescription: '',
        fromOtherInfo: {
          call: ''
        }
      })
      this.formData = Object.assign(this.formData, { teamList: list })
      this.$forceUpdate()
    },
    delTeam(list, index) {
      let that = this
      this.$confirm({
        content: '确认删除？',
        okText: '是',
        cancelText: '否',
        zIndex: 9999,
        onOk() {
          if (list[index].id) {
            that.deleteRelations.push(list[index].id)
          }
          list.splice(index, 1)
          that.$forceUpdate()
        },
        onCancel() {},
        class: ''
      })
    },
    addxg(list, index) {
      list.push({
        name: '',
        description: ''
      })
    },
    saveGx(params) {
      if (params.index > -1) {
        this.formData.gxList.splice(params.index + 1, 0, {
          anotherTermId: params.anotherTermId,
          call: params.call || '',
          gx:
            this.roleList.find(r => r.value == params.anotherTermId).label +
            'left' +
            (this.formData.processedName || '无译文名'),
          honorific: params.honorific || '',
          name: params.name,
          elseInfo: params.elseInfo || '',
          typeDesc: 'left',
          processedName: this.roleList.find(r => r.value == params.anotherTermId).label
        })
      } else {
        this.formData.gxList = []
        this.formData.gxList.push({
          anotherTermId: params.anotherTermId,
          call: params.call || '',
          gx:
            this.roleList.find(r => r.value == params.anotherTermId).label +
            'left' +
            (this.formData.processedName || '无译文名'),
          honorific: params.honorific || '',
          elseInfo: params.elseInfo || '',
          name: params.name,
          typeDesc: 'left',
          processedName: this.roleList.find(r => r.value == params.anotherTermId).label
        })
      }
      if (this.formData.gxList[0] && !this.formData.gxList[0].anotherTermId) {
        this.formData.gxList.shift()
      }
      this.$forceUpdate()
    },
    delxg(list, index) {
      list.splice(index, 1)
    },
    onChange(value, selectedOptions, item) {
      let text = ''
      text = value[0] + value[1] + this.formData.originName
      item.anotherTermId = value[0]
      item.gx = selectedOptions[0].label + value[1] + this.formData.processedName
      item.typeDesc = value[1]
      this.$forceUpdate()
    },
    show() {
      console.log('显示...', this.curRow)
      this.getTypeList()
      this.getTeamList()
      this.getRoleList()
    },
    teamChange(value, item) {
      item = Object.assign(item, { team: value, anotherTermId: value })
      this.$forceUpdate()
    },
    radioChange(e, item) {
      item = Object.assign(item, { honorific: e.target.value })
      this.$forceUpdate()
    },
    teamRadioChange(e) {
      this.formData = Object.assign(this.formData, {
        hasTeam: e.target.value,
        teamList: [
          {
            team: undefined,
            teamDescription: ''
          }
        ]
      })
      this.$forceUpdate()
    },
    edit() {
      let curRow = JSON.parse(JSON.stringify(this.curRow))
      this.formType = curRow.term.termType
      if (!this.roleList.length && this.formType == '人物') {
        this.$message.error('未获取到人物列表，无法编辑关系')
      }
      this.isEdit = true
      this.isSaved = false
      console.log('编辑...')
      if (this.formType == '人物') {
        this.formData = { ...curRow.term, relations: curRow.relations }
        if (this.formData.relations['团体关系']) {
          this.formData.hasTeam = 1
          this.formData.teamList = this.formData.relations['团体关系']
          this.formData.teamList?.forEach(item => {
            item.team = item.anotherTermId
            item.teamDescription = item.fromOtherInfo.call
          })
        } else {
          this.formData.hasTeam = 2
        }
        if (this.formData.relations['人物关系']) {
          this.formData.gxList = this.formData.relations['人物关系']
          this.formData.gxList?.forEach(item => {
            let name = ''
            let typeDesc = item.typeDesc
            if (item.termId == this.formData.id) {
              name = this.roleList.find(i => i.value == item.anotherTermId).label
            } else if (item.anotherTermId == this.formData.id) {
              name = this.roleList.find(i => i.value == item.termId).label
              if (item.typeDesc == 'right') {
                typeDesc = 'left'
              } else if (item.typeDesc == 'left') {
                typeDesc = 'right'
              } else {
                typeDesc = 'swap'
              }
            }
            item.gx = name + typeDesc + this.formData.processedName
            if (item.typeDesc == 'right') {
              if (item.otherInfo.honorific == '敬语') {
                item.honorific = item.otherInfo.honorific
              } else if (item.otherInfo.honorific == '平语') {
                item.honorific = item.otherInfo.honorific
              } else {
                item.honorific = ''
              }
              item.call = item.otherInfo.call
            } else {
              if (item.fromOtherInfo.honorific == '敬语') {
                item.honorific = item.fromOtherInfo.honorific
              } else if (item.fromOtherInfo.honorific == '平语') {
                item.honorific = item.fromOtherInfo.honorific
              } else {
                item.honorific = ''
              }
              item.call = item.fromOtherInfo.call
            }
          })
        } else {
          this.formData.gxList = []
        }
      } else {
        this.formData = { ...curRow.term }
      }
      console.log({ 'this.formData': this.formData })
      this.oldFormData = JSON.parse(JSON.stringify(this.formData))
    },
    beforeCroUpload(file, index) {
      this.uploadIndex = index
      const url = window.URL ? window.URL.createObjectURL(file) : window.webkitURL.createObjectURL(file)
      this.openCropper(url)
      return false
    },
    openCropper(url) {
      this.$refs.termsCropperModel.openModal(url)
    },
    openTeam() {
      console.log(this.$refs.termsTeamModel, 'openTeam')
      this.$refs.termsTeamModel.openModal()
    },
    handleChangeUpload(info, type) {
      if (info.file.status === 'done') {
        let params = this.getSaveFileParams(info.file)
        this.formData.subjectImgUrl[this.uploadIndex] = params.filePath
        this.removeOssFilePolicy(info.file)
        this.avatarLoading = false
        this.$forceUpdate()
      }
    },
    save() {
      /*       if (this.formData.originName && this.terms.filter(w => w.value == this.formData.originName&&w.type==this.formData.termTypeName).length) {
        this.$message.error('原文名不可重复！')
        return
      } */
      this.$refs['dataForm'].validate(async valid => {
        if (valid) {
          if (!this.isEdit) {
            if (this.formType == '人物') {
              this.formData.gxList?.forEach(item => {
                if (item.typeDesc == 'right') {
                  item.otherInfo = {
                    call: item.call,
                    honorific: item.honorific
                  }
                } else if (item.typeDesc == 'left') {
                  item.fromOtherInfo = {
                    call: item.call,
                    honorific: item.honorific
                  }
                } else {
                  item.fromOtherInfo = {
                    call: item.call,
                    honorific: item.honorific
                  }
                  item.otherInfo = {
                    call: item.call,
                    honorific: item.honorific
                  }
                }
              })
              if (this.formData.hasTeam == 1) {
                this.formData.teamList?.forEach(item => {
                  item.anotherTermId = item.team
                  item.typeDesc = 'left'
                  item.fromOtherInfo = {
                    call: item.teamDescription
                  }
                })
              }

              let relations = {
                人物关系: this.formData.gxList
              }
              if (!this.formData.gxList.filter(item => item.gx).length) {
                delete relations['人物关系']
              }
              if (this.formData.hasTeam == 1 && this.formType == '人物') {
                if (this.formData.teamList.filter(item => item.team).length) {
                  relations['团体关系'] = this.formData.teamList
                }
              }
              /*               if (
                this.formData.phoneticFamilyNameTextList?.map(p => p.value).join('') &&
                this.formData.phoneticGivenNameTextList?.map(p => p.value).join('')
              ) {
                this.formData.phoneticStatus = 1
              } else if (
                !this.formData.phoneticFamilyNameTextList?.map(p => p.value).join('') &&
                this.formData.phoneticGivenNameTextList?.map(p => p.value).join('')
              ) {
                this.formData.phoneticStatus = 2
              } else if (
                this.formData.phoneticFamilyNameTextList?.map(p => p.value).join('') &&
                !this.formData.phoneticGivenNameTextList?.map(p => p.value).join('')
              ) {
                this.formData.phoneticStatus = 3
              } else {
                this.formData.phoneticStatus = 0
              } */
              this.formData.processedName =
                (this.formData.processedFamilyName || '') + (this.formData.processedGivenName || '')
              const res = await postAction('/term/edit', {
                term: { ...this.formData, productionId: this.taskDetail.productionId, termType: this.formType },
                relations,
                deleteRelations: this.deleteRelations
              })
              if (res.code == 200) {
                this.formData.status = '待确认'
                if (this.curRow.term) {
                  this.curRow.term.status = '待确认'
                }
                this.$message.success('添加成功')
                this.isEdit = false
                this.visible = false
                this.initForm()
              } else {
                this.$message.error(res.msg)
              }
            } else {
              /*               if (this.formData.phoneticTextList?.map(p => p.value).join('')) {
                this.formData.phoneticStatus = 1
              } else {
                this.formData.phoneticStatus = 0
              } */
              /* this.formData.phoneticTextList?.forEach(item => {
                item.type = 'multiple'
              }) */
              const res = await postAction('/term/edit', {
                term: { ...this.formData, productionId: this.taskDetail.productionId, termType: this.formType }
              })
              if (res.code == 200) {
                this.formData.status = '待确认'
                if (this.curRow.term) {
                  this.curRow.term.status = '待确认'
                }
                this.$message.success('添加成功')
                this.visible = false
                this.initForm()
              } else {
                this.$message.error(res.msg)
              }
            }
          } else {
            this.formData.gxList?.forEach(item => {
              if (item.typeDesc == 'right') {
                item.otherInfo = {
                  call: item.call,
                  honorific: item.honorific
                }
              } else if (item.typeDesc == 'left') {
                item.fromOtherInfo = {
                  call: item.call,
                  honorific: item.honorific
                }
              } else {
                item.fromOtherInfo = {
                  call: item.call,
                  honorific: item.honorific
                }
                item.otherInfo = {
                  call: item.call,
                  honorific: item.honorific
                }
              }
            })
            this.formData.teamList?.forEach(item => {
              item.anotherTermId = item.team
              item.typeDesc = 'left'
              item.fromOtherInfo = {
                call: item.teamDescription
              }
            })
            let relations = {
              人物关系: this.formData.gxList
            }
            if (!this.formData.gxList?.filter(item => item.gx).length) {
              delete relations['人物关系']
            }
            if (this.formData.hasTeam == 1 && this.formType == '人物') {
              if (this.formData.teamList?.filter(item => item.team).length) {
                relations['团体关系'] = this.formData.teamList
              }
            }
            if (this.formType == '人物') {
              /*               if (
                this.formData.phoneticFamilyNameTextList?.map(p => p.value).join('') &&
                this.formData.phoneticGivenNameTextList?.map(p => p.value).join('')
              ) {
                this.formData.phoneticStatus = 1
              } else if (
                !this.formData.phoneticFamilyNameTextList?.map(p => p.value).join('') &&
                this.formData.phoneticGivenNameTextList?.map(p => p.value).join('')
              ) {
                this.formData.phoneticStatus = 2
              } else if (
                this.formData.phoneticFamilyNameTextList?.map(p => p.value).join('') &&
                !this.formData.phoneticGivenNameTextList?.map(p => p.value).join('')
              ) {
                this.formData.phoneticStatus = 3
              } else {
                this.formData.phoneticStatus = 0
              } */
              this.formData.processedName =
                (this.formData.processedFamilyName || '') + (this.formData.processedGivenName || '')
            } else {
              /*               if (this.formData.phoneticTextList?.map(p => p.value).join('')) {
                this.formData.phoneticStatus = 1
              } else {
                this.formData.phoneticStatus = 0
              } */
              /* this.formData.phoneticTextList?.forEach(item => {
                item.type = 'multiple'
              }) */
            }
            const res = await postAction('/term/edit', {
              term: {
                ...this.formData,
                productionId: this.taskDetail.productionId,
                termType: this.formType,
                anotherTermId: this.formData.team
              },
              relations,
              deleteRelations: this.deleteRelations
            })
            if (res.code == 200) {
              this.isEdit = false
              /* this.visible = false */
              /* this.initForm() */
              this.formData.status = '待确认'
              let data = JSON.parse(JSON.stringify(this.formData))
              this.curRow.term = { ...data }
              this.$emit('getTerms')
              this.$message.success('保存成功')
            } else {
              this.$message.error(res.msg)
            }
          }
        } else {
          this.$message.error('请填写必填项')
        }
      })
    },
    async upImg(file) {
      await this.beforeUpload(file)
      const token = Vue.ls.get(ACCESS_TOKEN)
      let formData = new FormData()
      this.ossUploadData.key = this.ossUploadData.key + '.' + file.type
      for (let key in this.ossUploadData) {
        formData.append(key, this.ossUploadData[key])
      }
      formData.append('file', file)
      axios
        .post(this.ossUploadUrl, formData, {
          'Content-Type': 'multipart/form-data;',
          headers: {
            'X-Access-Token': token
          }
        })
        .then(() => {
          file.status = 'done'
          this.handleChangeUpload({ file })
        })
    },
    initForm() {
      this.curRow = { term: null }
      this.formType = ''
      this.formData = {
        subjectImgUrl: ['', '', ''],
        phoneticText: '',
        hasTeam: 2,
        gxList: [],
        phoneticFamilyNameTextList: [],
        phoneticGivenNameTextList: [],
        phoneticNickNameTextList: [],
        phoneticTextList: [],
        teamList: {
          team: undefined,
          teamDescription: ''
        },
        characters: [
          {
            name: '',
            description: ''
          }
        ]
      }
      this.showBottom = false
      this.showTop = true
      this.showCurTop = true
      this.showCurBottom = false
      this.deleteRelations = []
      this.$emit('getTerms')
      if (this.editor) this.editor.destroy()
      if (this.editor2) this.editor2.destroy()
      if (this.editor3) this.editor3.destroy()
    }
  }
}
</script>

<style lang="less" scoped>
.term-prev {
  position: absolute;
  top: 250px;
  font-size: 20px;
  left: 13px;
  cursor: default !important;
}
.term-next {
  position: absolute;
  top: 250px;
  font-size: 20px;
  right: 15px;
  cursor: default !important;
}
.change-page {
  cursor: pointer !important;
  &:hover {
    color: var(--theme-color);
  }
}
.reject-button {
  line-height: 32px;
  display: inline-block;
  height: 32px;
  width: 50px;
  text-align: center;
  border-radius: 4px;
  transition: all 0.3s;
  cursor: pointer;
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
}
.reject {
  &:hover {
    color: orange;
  }
}
.send {
  color: var(--theme-color);
}
.origin-other-item {
  position: relative;
}
.origin-other-item::before {
  content: '*';
  display: inline-block;
  color: red;
  font-size: 14px;
  position: absolute;
  top: 2px;
  left: -10px;
}
.w-e-text-container {
  height: 30px !important; /*!important是重点，因为原div是行内样式设置的高度300px*/
}
/deep/ .w-e-scroll {
  overflow: hidden !important;
}
/deep/ .img-editor p {
  margin: 0 !important;
  margin-top: 3px !important;
}
/deep/ .w-e-text-placeholder {
  font-style: inherit !important;
  left: 15px !important;
  top: -5px !important;
  width: 90% !important;
}
.img-editor {
  border: 1px solid gainsboro;
  transition: 0.3s all;
  border-radius: 5px;
  &:hover {
    border-color: var(--theme-color);
  }
}
/deep/ .upload-item {
  .ant-form-item-children {
    display: flex;
    position: relative;
  }
}
/deep/ .phonetic-item {
  .ant-form-item-children {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
}
/deep/ .avatar-uploader-first .ant-upload-select-picture-card {
  border: 2px solid green !important;
}
.gx-row {
  transform: 0.3s all;
  &:hover {
    background-color: rgb(240, 248, 255);
  }
}
/deep/ .ant-upload {
  position: relative !important;
  .anticon-close-circle {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    transition: 0.3s all;
    &:hover {
      color: #5389df;
    }
  }
}
.other-item {
  line-height: 40px;
  margin-bottom: 12px;
  cursor: pointer;
  &:hover {
    color: var(--theme-color);
  }
  transition: all 0.3s;
  .anticon-right {
    transition: transform 0.3s;
  }
  .open-oher-item {
    transform: rotate(90deg);
  }
  .close-oher-item {
    transform: rotate(0deg);
  }
}
.avatar-show-mini {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  top: -5px;
}
.end-btns-table {
  position: absolute;
  right: 0;
  transform: scale(0.9, 0.9);
  top: 0;
  display: flex;
  flex-wrap: wrap;
  width: 40px;
  justify-content: center;
  align-items: center;
}
.gx-list {
  line-height: 50px;
  .ant-row {
    border-bottom: 1px solid rgb(232, 232, 232);
  }
}
.gx-list-show {
  padding-bottom: 20px;
}
.modal-title-button {
  line-height: 50px;
  height: 50px;
  display: flex;
  justify-content: end;
}
.avatar-uploader {
  position: relative;
}
/deep/ .ant-upload.ant-upload-select-picture-card {
  width: 106px !important;
  height: 105px !important;
  border-radius: 50%;
}
.check-origin {
  > div {
    > span {
      color: red;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        opacity: 0.7;
      }
    }
  }
}
.avatar-show {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  overflow: hidden;
}
.avatar-show-mini {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-top: 10px;
}
.end-btns {
  position: absolute;
  right: -40px;
  top: -5px;
  display: flex;
  flex-wrap: wrap;
  width: 40px;
  justify-content: center;
  align-items: center;
}
.ant-input-group .ant-input:hover {
  z-index: 2 !important;
  border-right-width: 1px;
}
.ant-input-group .ant-input:focus {
  z-index: 2 !important;
  border-right-width: 1px;
}
.term-form {
  padding-left: 15px;
  max-height: 500px;
  overflow-y: auto;
}
::-webkit-scrollbar-button {
  height: 0;
}
::-webkit-scrollbar {
  border-radius: 0;
  position: relative;
  width: 3px;
}
::-webkit-scrollbar-track {
  border-radius: 0;
  background-color: #fff;
}
::-webkit-scrollbar-track-piece {
  border-radius: 0;
}
::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: #c1c1c1;
}
.gx-row {
  .ant-col {
    line-height: 50px;
    min-height: 50px;
  }
}
.modal-title {
  height: 36px;
  background-color: #ebebeb;
  padding: 2px 12px 2px 12px;
  line-height: 32px;
  font-weight: 400;
}
/deep/ .translate-modal .ant-modal-header {
  padding: 0 !important;
}
/deep/ .translate-modal .ant-modal-body {
  padding: 12px 24px;
}
/deep/ .translate-modal .ant-modal-close {
  height: 36px;
}
/deep/ .translate-modal .ant-modal-close-x {
  height: 36px;
  line-height: 36px;
}
</style>
